<template>
  <!-- Sound files table -->
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.sound_files')"
        :showButton="false"
      />

      <div class="card relative table-wrapper">
        <!-- Table -->
        <DataTable
          ref="dt"
          selectionMode="single"
          dataKey="id"
          :paginator="true"
          :rows="10"
          scrollable
          removableSort
          :scrollHeight="`${scrollHeight}px`"
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          :value="soundFiles"
          filterDisplay="menu"
          :rowClass="rowClass"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} sound files"
          :rowsPerPageOptions="[5, 10, 25]"
          v-if="soundFiles && soundFiles.length"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
          :globalFilterFields="['filename', 'description']"
        >
          <template #header>
            <div class="flex gap-2 align-items-center justify-between">
              <div>
                <Button
                  label="New"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  label="Delete"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>
              <div class="flex gap-2 items-center">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Search..."
                  />
                </IconField>
                <Export
                  :dt="dt"
                  :tableData="soundFiles"
                  :columns="soundFilesHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="filename"
            style="width: 20%"
            :header="$t('xpbx.table-field.name')"
          >
            <template #body="{ data }">
              <div class="relative inline-block">
                {{ data?.filename }}
                <AudioPlayer :id="data.id" />
              </div>
            </template>
          </Column>
          <Column
            sortable
            field="description"
            style="width: 20%"
            :header="$t('xpbx.table-field.description')"
          >
            <template #body="{ data }">
              {{ data?.description }}
            </template>
          </Column>
          <Column
            sortable
            field="filetype"
            style="width: 20%"
            :header="$t('xpbx.table-field.filetype')"
          >
            <template #body="{ data }">
              {{ data?.filetype }}
            </template>
          </Column>
          <Column
            sortable
            field="filesize"
            style="width: 20%"
            :header="$t('xpbx.table-field.filesize')"
          >
            <template #body="{ data }">
              {{ data?.filesize }}
            </template>
          </Column>
          <Column
            sortable
            field="created_at"
            style="width: 20%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="{ data }">
              <DateItem :value="data?.created_at" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="selectedRecord?.description">{{
            $t("notify.xpbx.message.confirm_delete", {
              delete: `sound file ${selectedRecord?.description}`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            text
            @click="deleteRecordsDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            text
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="recordDialog"
        :style="{ width: '500px' }"
        :header="
          isEdit
            ? $t('xpbx.pages.heading.edit_sound_file')
            : $t('xpbx.pages.heading.create_sound_file')
        "
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Description -->
        <div class="field mb-1">
          <label for="description">{{
            $t("xpbx.placeholders.description")
          }}</label>
          <InputText
            id="description"
            v-model="soundFile.description"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !soundFile.description }"
          />
          <small
            class="p-error"
            v-if="submitted && !!errors?.description?.[0]"
            >{{ errors?.description?.[0] }}</small
          >
        </div>
        <QueueHint :title="$t('xpbx.hints.sound-files.description')" />

        <!-- Filetype -->
        <div class="field">
          <label class="w-28 text-left" for="is_active">{{
            $t("xpbx.labels.filetype")
          }}</label>
          <div class="w-1/2">
            <form-select
              v-model="soundFile.filetype"
              :options="fileTypeOptions"
              name="is_active"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.sound-files.filetype')" />

        <!-- Name -->
        <div class="field mb-1 mt-2" v-if="soundFile?.filename">
          <div class="mb-2">
            <label class="mr-2">{{ $t("xpbx.placeholders.filename") }}</label>
            <span>{{ soundFile.filename }}</span>
          </div>
          <div v-if="audioFileSize">
            <label class="mr-2">{{ $t("xpbx.placeholders.size") }}</label>
            <span>{{ audioFileSize }}</span>
          </div>
        </div>

        <!-- File -->
        <div class="flex items-center column-gap-4 mb-1">
          <FileUpload
            mode="basic"
            name="demo[]"
            url="/api/upload"
            accept="audio/*"
            :maxFileSize="5000000"
            customUpload
            :auto="true"
            @uploader="customUpload"
          />
          <!-- Player on Edit Screen -->
          <div
            v-if="isEdit && soundFile?.id && !audioFile"
            class="ml-4 cursor-pointer"
          >
            <FullAudioPlayer :id="soundFile.id" />
          </div>
          <audio controls v-if="audioFile" class="audio-player ml-4">
            <source src="" type="audio/mpeg" ref="audioPlayer" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <small class="p-error" v-if="submitted && !audioFile && !isEdit">{{
          $t("xpbx.errors.file_required")
        }}</small>
        <QueueHint :title="$t('xpbx.hints.sound-files.file')" />

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            text
            @click="recordDialog = false"
          />
          <Button label="Save" icon="pi pi-check" text @click="create" />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TableSettings from "@/modules/xpbx/components/UI/table-settings";
import useSoundFiles from "@/modules/xpbx/composables/useSoundFiles";
import StatusIcon from "@/modules/xpbx/components/UI/status-icon/index.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Textarea from "primevue/textarea";
import AudioPlayer from "./audio.vue";
import FullAudioPlayer from "./audio-player.vue";
import FileUpload from "primevue/fileupload";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { validateTimezone } from "@/composables/auth/templateValidations";
import { soundFilesHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "SoundFiles",

  components: {
    PageHeader,
    TableSettings,
    StatusIcon,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Export,
    Dialog,
    IconField,
    InputIcon,
    Textarea,
    DateItem,
    FileUpload,
    AudioPlayer,
    FullAudioPlayer,
    QueueHint,
  },

  setup() {
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const recordDialog = ref(false);
    const audioPlayer = ref(null);
    const selectedRecord = ref(null);
    const audioBlob = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const scrollHeight = ref(window.innerHeight - 290);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const audioFile = ref(null);
    const audioFileSize = ref(null);

    const {
      findSoundFIle,
      findSoundFIles,
      createSoundFIle,
      updateSoundFIle,
      deleteSoundFIle,
      soundFile,
      soundFiles,
    } = useSoundFiles();

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        console.log("unselect", event.data);
        soundFile.value = event.data;
        isEdit.value = true;
        recordDialog.value = true;

        audioFileSize.value = event.data.filesize;
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && soundFile.value?.id === data?.id,
        },
      ];
    };

    // Data Table Methods
    const openNew = () => {
      reset();
      isEdit.value = false;
      recordDialog.value = true;
    };

    const customUpload = async (event) => {
      const file = event.files[0];

      audioFile.value = file;
      audioFileSize.value = file.size;
      soundFile.value.filename = file.name;

      setTimeout(() => {
        const audioPlayerElement = document.querySelector(".audio-player");

        audioPlayerElement.src = URL.createObjectURL(file);
        // audioPlayer.value.src = URL.createObjectURL(file);
      }, 300);
    };

    const reset = () => {
      soundFile.value = {
        filename: "",
        description: "",
        filetype: "ivr",
        audio: null,
      };
      audioFile.value = null;
      submitted.value = false;
      audioFileSize.value = null;
      selectedRecord.value = null;
    };

    const closeDialog = (value) => {
      recordDialog.value = value;
    };

    const updateSound = async () => {
      const formData = new FormData();
      if (audioFile.value) formData.append("audio", audioFile.value);
      formData.append("filename", soundFile.value.filename);
      formData.append("filetype", soundFile.value.filetype || "ivr");
      formData.append("description", soundFile.value.description);

      await updateSoundFIle(formData, selectedRecord.value.id);
      isEdit.value = false;
      recordDialog.value = false;
    };

    const createSound = async () => {
      if (!audioFile.value) return;

      const formData = new FormData();
      formData.append("audio", audioFile.value);
      formData.append("filename", soundFile.value.filename);
      formData.append("filetype", soundFile.value.filetype || "ivr");
      formData.append("description", soundFile.value.description);

      await createSoundFIle(formData);
      recordDialog.value = false;
      submitted.value = false;
    };

    const create = async () => {
      submitted.value = true;
      const isValid = validateTimezone(soundFile.value);

      if (isValid) {
        if (isEdit.value === true) {
          await updateSound();
          submitted.value = false;
        } else {
          await createSound();
        }
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteSoundFIle(selectedRecords.value.id);
      deleteRecordsDialog.value = false;
    };

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    const fileTypeOptions = [
      { name: "File for IVR", value: "ivr" },
      { name: "Music on HOLD", value: "moh" },
    ];

    watch(recordDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
      await findSoundFIles();
    });

    return {
      dt,
      audioBlob,
      filters,
      isEdit,
      errors,
      soundFile,
      soundFiles,
      audioFile,
      audioFileSize,
      recordDialog,
      soundFilesHeaders,
      submitted,
      rowClass,
      audioPlayer,
      scrollHeight,
      selectedRecord,
      selectedRecords,
      fileTypeOptions,
      deleteRecordsDialog,
      create,
      reset,
      openNew,
      findSoundFIle,
      findSoundFIles,
      createSoundFIle,
      updateSoundFIle,
      deleteSoundFIle,
      closeDialog,
      customUpload,
      onRowSelect,
      onRowUnselect,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>


<style lang="scss" scoped>
.p-button.add-record-button {
  background: rgba(#32bcad, 0.8);
  border: 1px solid rgba(#32bcad, 0.8);

  &:hover {
    background: rgba(#32bcad, 1);
    border: 1px solid #32bcad;
  }
}

.p-button.delete-button {
  background: #f05a94;
  border: 1px solid #f05a94;

  &:hover {
    background: #f05a94;
  }
}

.p-button.delete-button.p-disabled {
  background: rgba(#f05a94, 0.5);
  border: 1px solid rgba(#f05a94, 0.5);
  border-color: rgba(#f05a94, 0.5);

  &:hover {
    background: rgba(#f05a94, 0.8);
    border-color: rgba(#f05a94, 0.8);
  }
}
</style>