<template>
  <div>
    <audio v-if="audioUrl" :src="audioUrl" controls></audio>
    <Loader v-else :size="'24px'" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import useSoundFiles from "@/modules/xpbx/composables/useSoundFiles";

export default {
  name: "FullAudioPlayer",

  components: {
    Loader,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { findSoundFIle } = useSoundFiles();
    const audioUrl = ref(null);

    const fetchAudio = async () => {
      try {
        const response = await findSoundFIle(props.id);
        if (response) {
          const blob = new Blob([response], { type: "audio/mpeg" });

          const blobUrl = URL.createObjectURL(blob);

          console.log("blobUrl", blobUrl);
          audioUrl.value = blobUrl;
        }
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {
      await fetchAudio();
    });

    return {
      audioUrl,
    };
  },
};
</script>
